:root {
  /* root font-size */
  font-size: 100%;

  /* colors */
  --c146-3x4-0: rgba(18,10,48,1);
  --c146-3x4-1: rgba(37,21,94,1);
  --c146-3x4-2: rgba(72,43,179,1);
  --c146-3x4-3: rgba(14,144,204,1);
  --c146-3x4-4: rgba(14,200,179,1);

  /***********************************************\
  |* font sizes, relative to the root font-size  *|
  |* these are based on the following formula    *|
  |*                                             *|
  |*   φ = 1.61803398875...                      *|
  |*   h1 = φ^(3/2)                              *|
  |*   h_n = h1 * φ^( -(n-1)/2 )                 *|
  |*                                             *|
  |*   thus, for h3: h1 * φ^(-(3-1)/2 ) = 1.272  *|
  |*                                             *|
  |*   h_n is the function to get the value of   *|
  |*   dividing a number, h1, by sqrt of         *|
  |*   another number, φ, n times.               *|
  |*                                             *|
  \***********************************************/

  --h-6-font-size: 11.0902rem;
  --h-5-font-size: 8.7186rem;
  --h-4-font-size: 6.8541rem;
  --h-3-font-size: 5.3884rem;
  --h-2-font-size: 4.2361rem;
  --h-1-font-size: 3.3302rem;
  --h0-font-size: 2.6180rem;
  --h1-font-size: 2.0582rem;
  --h2-font-size: 1.6180rem;
  --h3-font-size: 1.2720rem;
  --h4-font-size: 1.000rem;
  --h5-font-size: 0.7862rem;
  --h6-font-size: 0.6180rem;
  --h7-font-size: 0.4859rem;
  --h8-font-size: 0.3820rem;
  --h9-font-size: 0.3003rem;
  --h10-font-size: 0.2361rem;
  --h11-font-size: 0.1856rem;
  --h12-font-size: 0.1459rem;
  --h13-font-size: 0.1147rem;
  --h14-font-size: 0.0902rem;
}

* {
    margin:0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    color: var(--c146-3x4-0); 
}

a {
  text-decoration: none;
}

body {
  padding: var(--h1-font-size);
  height: 100vh;
}

#top-nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: var(--h0-font-size);
  margin-bottom: var(--h2-font-size);
}

#top-nav a {
  display: flex;
  align-items: center;
  justify-content: center;
}

#top-nav a img {
  width: var(--h1-font-size);
}

#close {
  z-index: 5;
}

#primary-navegation {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  z-index: 4;
  background-color: rgba(0,0,0,0.8);
  overflow-x: hidden;
  transition: 0.5s;
}

#primary-navegation.show-primary-nav {
  width: 100%;
}

.primary-navegation-content {
  margin: auto;
  list-style-type: none;
  position: relative;
  top: 25%;
  width: 25%;
  text-align: center;
  margin-top: 30px;
  font-weight: 300;
}

.primary-navegation-content li a {
  font-size: var(--h1-font-size);
  padding-bottom: var(--h1-font-size);
  color: var(--c146-3x4-3);
  transition: 0.7s;
}

.primary-navegation-content li a:hover,
.primary-navegation-content li a:focus {
  color: var(--c146-3x4-4);
}

img {
}

h1,h2,h3,h4,h5,h6 {
  color: var(--c146-3x4-4);
}

p {
  font-weight: 300;
  font-size: var(--h4-font-size);
  margin-top: var(--h6-font-size);
  margin-bottom: var(--h8-font-size);
  line-height: var(--h2-font-size);
}

p a {
  color: var(--c146-3x4-3);
  border-style: none none solid none;
  border-width: var(--h13-font-size);
  border-color: var(--c146-3x4-4);
}

h1 {
  font-weight: 400;
  font-size: var(--h1-font-size);
  margin-top: var(--h3-font-size);
  margin-bottom: var(--h5-font-size);
  line-height: var(--h0-font-size);
}

h2 {
  font-weight: 400;
  font-size: var(--h2-font-size);
  margin-top: var(--h4-font-size);
  margin-bottom: var(--h6-font-size);
  line-height: var(--h1-font-size);
}

h3 {
  font-weight: 300;
  font-size: var(--h3-font-size);
  margin-top: var(--h4-font-size);
  margin-bottom: var(--h7-font-size);
  line-height: var(--h2-font-size);
}

h4 {
  font-weight: 400;
  font-size: var(--h4-font-size);
  margin-top: var(--h6-font-size);
  margin-bottom: var(--h8-font-size);
  line-height: var(--h3-font-size);
}

.hero--svg-container {
  min-width: 40%;
}

.hero--svg {
  display: block;
  height: auto;

  width: 100%;
  border-radius: 25px;
  margin: auto;
  margin-top: var(--h4-font-size);
  margin-bottom: var(--h8-font-size);
  max-width: 40vmax;
}

@media screen and (min-width: 24em) {
  :root {
    font-size: 109.02%;
  }
  
  body {
    padding-left: var(--h0-font-size);
    padding-right: var(--h0-font-size);
  }
}

@media screen and (min-width: 30.5em) {
  :root {
    font-size: 111.47%;
  }
  
  body {
    padding-left: var(--h-1-font-size);
    padding-right: var(--h-1-font-size);
  }
}

@media screen and (min-width: 38.8em) and (orientation: landscape) {
  :root {
    font-size: 114.59%;
  }

  body {
    padding-left: var(--h-2-font-size);
    padding-right: var(--h-2-font-size);
  }

  .hero {
    display: flex;
  }

  .hero--svg {
    padding-right: var(--h1-font-size);
  }
  
  .hero--text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (min-width: 49.4em) {
  :root {
    font-size: 118.56%;
  }

  body {
    padding-left: var(--h-3-font-size);
    padding-right: var(--h-3-font-size);
  }
}

@media screen and (min-width: 62.8em) {
  :root {
    font-size: 123.61%;
  }

  body {
    padding-left: var(--h-4-font-size);
    padding-right: var(--h-4-font-size);
  }
}

@media screen and (min-width: 79.9em) {
  :root {
    font-size: 130.03%;
  }

  body {
    padding-left: var(--h-5-font-size);
    padding-right: var(--h-5-font-size);
  }

  .hero--text h1 {
    font-size: var(--h-1-font-size);
    margin-top: var(--h1-font-size);
    margin-bottom: var(--h3-font-size);
    line-height: var(--h-2-font-size);
  }

  .hero--text p {
    font-size: var(--h3-font-size);
    margin-top: var(--h5-font-size);
    margin-bottom: var(--h7-font-size);
    line-height: var(--h2-font-size);
  }
}
